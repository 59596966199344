export const CATEGORIES = {
    "United States": {
        "us_diversity_visa_lottery": {"option": "US Diversity Visa (DV) Lottery"},
        "us_visa": {"option": "US Visa 2x2 inch (600x600 px, 51x51mm)"},
        "us_passport": {"option": "US Passport 2x2 inch (51x51 mm)"},
        "us_green_card": {"option": "US Green Card (Permanent Resident) 2x2\""},
        "us_citizenship": {"option": "US Citizenship (naturalization) 2x2 inch (51x51 mm)"},
        "us_employment_authorization": {"option": "US Employment Authorization 2x2 inch (51x51 mm)"},
        "us_metrocard": {"option": "US NY MTA Metrocard for Seniors"},
        "us_crew_visa": {"option": "USA crew visa 2x2 inch"},
        "us_petition_for_alien_relative_form_i_130": {"option": "USA Form I-130 2x2 inch"},
        "us_nursing_license": {"option": "USA Nursing License 2x2 inch"},
        "us_reentry_permit": {"option": "USA Re-entry Permit 2x2 inch"},
        "us_welding_certification": {"option": "USA welding certificate 2x2 inch"},
        "us_advance_parole": {"option": "USA advance parole 2x2 inch"},
        "us_veteran_id_card": {"option": "US Veteran ID Card 2x2 inch"},
        "us_passport_card": {"option": "US passport card 2x2 inch"},
        "us_sat": {"option": "USA SAT 2x2 inch"},
        "us_nfa_atf_register_firearm": {"option": "US NFA ATF form 2x2 inch"},
        "us_uscis": {"option": "USCIS 2x2 inch"},
        "us_cibt": {"option": "CIBTvisas visa photo (any country)"},
        "us_visacentral": {"option": "VisaCentral visa photo (any country)"},
        "us_travisa": {"option": "Travisa visa photo (any country)"},
        "us_visahq": {"option": "VisaHQ visa photo (any country)"},
        "us_visa_headquarters": {"option": "Visa Headquarters visa photo (any country)"}
    },
}