import React from 'react';
import ReactMarkdown from 'react-markdown';
import {MARKDOWN_TEXT} from './disclaimer_text.js';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

const Disclaimer = () => {

    const [contactModal, setContactModal] = useState(false)

    const openModal = () => {
        setContactModal(!contactModal)
    }
    


    return (
        <div>
            <div>© 2024, by MyDocuPic</div> 
            <span style={{ color: 'blue', cursor: 'pointer' }}  onClick={() => openModal()} >Disclaimers</span>
            <Modal
            size="lg"
            show={contactModal}
            onHide={openModal}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
            <Modal.Title>Disclaimers</Modal.Title>
            </Modal.Header>
                <div className="card border-0">
                    <div className="card-body" style={{ textAlign: 'left' }}>
                        <ReactMarkdown className="markdown-content">{MARKDOWN_TEXT}</ReactMarkdown>
                    </div>
                </div>
            </Modal>
        </div>

    );
}
export default Disclaimer;

