import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { CATEGORIES } from '../../categories_usa';
import DocumentUpload from '../DocumentUpload/DocumentUpload';

const InfoFormulary = (props) => {
  return (
    <Card className='text-start d-flex'>
      <Card.Body>
        <Card.Title>Upload Photo</Card.Title>
        <Card.Text style={{ marginLeft: '1.5rem' }}>
          Simply capture a picture with your smartphone or camera against any backdrop and upload it here. Our advanced technology transforms your image into a professional-quality photo, ready for your application. For optimal results, use a solid-colored background.
        </Card.Text>
        <Card.Subtitle className="m-2">Select Country</Card.Subtitle>
            <Form.Select aria-label="Default select example" style={{ marginLeft: '1.5rem', width: '90%' }}>
            { 
              Object.keys(CATEGORIES).map( (country, index) => {
                return(<option key={index} value={country}>
                  {country}
                </option>)
              })
            }
    </Form.Select>
    <Card.Subtitle className="m-2">Select Document</Card.Subtitle>
        <Form.Select aria-label="Default select example" xs={4} style={{ marginLeft: '1.5rem', width: '90%' }}>
        {Object.keys(CATEGORIES['United States']).map((subKey) => {
                      return (
                        <option key={subKey} value={subKey}>
                          {CATEGORIES['United States'][subKey].option}
                        </option>
                      );
                    })
        }
    </Form.Select>
    <DocumentUpload onImageChange={props.onImageChange}></DocumentUpload>
    </Card.Body>
  </Card>
  );
}

export default InfoFormulary;