import './App.css';
import Disclaimer from './components/Disclaimer/Disclaimer';
import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarmain from './components/NavbarMain/NavbarMain';
import ParentForm from './components/ParentForm/ParentForm';


function App() {
  const contentRef = useRef(null);

  useEffect(() => {
    const adjustContentPadding = () => {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      if (contentRef.current) {
        contentRef.current.style.marginTop = `${navbarHeight}px`;
        contentRef.current.style.height = `calc(85vh - ${navbarHeight}px)`;
      }
    };

    window.addEventListener('resize', adjustContentPadding);
    adjustContentPadding();

    return () => {
      window.removeEventListener('resize', adjustContentPadding);
    };
  }, []);


  return (
      <div className="App">
        <Navbarmain />
        <div className="content-container container-fluid d-flex justify-content-center align-items-center" ref={contentRef}>
          <div className="container">
            <div className="row">
              <ParentForm />
            </div>
            <div className="row" style={{ paddingTop: '35px' }}>
              <Disclaimer />
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
