import ImageAndWatermark from '../ImageAndWatermark/ImageAndWatermark';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import source from './source.jpg';
import result from './result.jpg';
import InfoFormulary from '../InfoFormulary/InfoFormulary';


const postImage = async (b64image) => {
  const url = 'https://api.mydocupic.com/process_image';

  const data = {
    image: b64image
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Re-throw the error to handle it in the caller if needed
  }
};

const ParentForm = () => {
    const [imageSource, setImageSource] = useState(source);
    const [imageResult, setImageResult] = useState(result);
    const [paymentLink, setPaymentLink] = useState('');

    const handleImageChange = (image) => {
        setImageSource(URL.createObjectURL(image));
        setImageResult('Loading');
        // Transform the image to a base64 string
        const reader = new FileReader();
      
        reader.onload = () => {
          // reader.result contains the base64 string
          const base64String = reader.result;
          // console.log(base64String);
          // The regex pattern
          const pattern = /data:image\/(?:jpeg|png);base64,/g;

          // Using the replace method to remove the matched text
          const filteredText = base64String.replace(pattern, "");
          console.log(filteredText)
          // Call the api and set the resulting image 
          postImage(filteredText)
            .then(data => handleReturnImage(data))
            .catch(error => console.error('Error:', error));
        };
        
        reader.onerror = (error) => {
          console.log('Error: ', error);
        };
        
        // Read the file as a Data URL (base64 string)
        reader.readAsDataURL(image);
    }

    const handleReturnImage = (data) => {
      setImageResult(`data:image/png;base64,${data.watermarked_image}`);
      setPaymentLink(data.payment_link)
    }

  return (
    <>     
      <Col>
          <InfoFormulary onImageChange={handleImageChange}></InfoFormulary>         
      </Col>
      <Col>
          <ImageAndWatermark source={imageSource} result={imageResult} paymentLink={paymentLink}></ImageAndWatermark>         
      </Col>
    </>
  );
}   

export default ParentForm;