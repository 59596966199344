import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'

const Contact = () => {

    const [show, setShow] = useState(false);

    const handleModal = () => setShow(!show);

    return (
    <>
      <Button variant="primary" onClick={handleModal}>
        Contact
      </Button>

      <Modal show={show} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Information:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
            Email: <div style={{fontWeight: "bold", display: "inline"}}>support@mydocupic.com</div>
            <br></br>
            <br></br>
            <div style={{fontStyle: 'italic'}}> If the picture was rejected send a screenshot of the rection email or the page. </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )

}

export default  Contact;