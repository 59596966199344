import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import { useSearchParams } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { saveAs } from 'file-saver';



const ImageAndWatermark = (props) => {
  const [searchParams] = useSearchParams();
  const  id = searchParams.get("id");
  const [photo, setPhoto] = useState(null);
  const [status, setStatus] = useState(null);

  const handleDownload = (base64String, id) => {

    // Remove the prefix from the base64 string
    const base64Data = base64String.split(",")[1]

    //Conver base64 string to a Blod
    const byteCharacters = atob(base64Data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    // Use file-saver to save the Blob as a file
    saveAs(blob, `${id}.jpeg`);
  }

  const getPictureFromDB = async (paramsId) => {
    const url = 'https://api.mydocupic.com/image?id=' + paramsId;
    const delay = ms => new Promise(res => setTimeout(res, ms));

    try {
      let response = await fetch(url);
      for (let i = 0; i < 3; i++) {
        if (response.ok) {
          const responseData = await response.json();
          return [`data:image/jpeg;base64,${responseData.message}`, response.ok, response.status];
        }
        await  delay(5000)
        response = await fetch(url);
      }
      const responseData = await response.json();
      return [responseData.message, response.ok, response.status];
    } catch (error) {
      console.error('Error:', error);
      throw error; // Re-throw the error to handle it in the caller if needed
    }
  };

  useEffect(() => {
    console.log(id)
    if (id) {
      const fetchImage = async () => {
        try {
          const [photob64, responseOk, responseStatus] = await getPictureFromDB(id);
          setPhoto(photob64)
          setStatus(responseStatus);
          responseStatus &&  handleDownload(photo, id)
          
          //handleDownload(photo, id) 
        } catch (error) {
          console.error('Failed to fetch image:', error);
        }
      };

      fetchImage();
    }
  }, []);

  if (id) {
    return photo == null ? (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
        <Spinner animation="border" variant="primary" />
      </Container>
    ) : status ? (
      <Card className='text-start card-limited-height' style={{ border: 'none' }} >
        <Card.Body>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Card.Header style={{ background: '#e9ecef' }}>Result</Card.Header>
              <Card.Img variant="top" src={photo} alt="Base64" className="image-size" />
              <Col className="d-flex justify-content-end">
                <Button className="mt-5" onClick={() => {handleDownload(photo, id)}}>Download</Button>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ) : (<Alert variant="danger" dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <p>
          {photo}
        </p>
      </Alert>);
  } else {
    return (
      <Card className='text-start' style={{ border: 'none' }}>
        <Card.Body>
          <Row>
            <Col>
              <Card.Header style={{ background: '#e9ecef' }}>Source</Card.Header>
              <Card.Img variant="top" src={props.source} />
            </Col>
            <Col>
              <Card.Header style={{ background: '#e9ecef' }}>Result</Card.Header>
              {
                props.result === "Loading" ? 
                  <Container className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Spinner animation="border" variant="primary" />
                  </Container>
                : (
                  <div>
                    <Card.Img variant="top" src={props.result} />
                    {props.paymentLink && (
                      <Col className="d-flex justify-content-end">
                        <Button href={props.paymentLink} className="mt-5">Checkout</Button>
                      </Col>
                    )}
                  </div>
                )
              }
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default ImageAndWatermark;