import React from 'react';
import logo from './logo_scan.svg';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import  Contact from '../Contact/Contact'

function Navbarmain() {

    return (
        <>
            <Navbar bg="primary" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img alt="Logo" src={logo}/> {' '}
                        MyDocuPic <Badge bg="secondary">Passport & Visa Photos in Seconds</Badge>
                    </Navbar.Brand>
                    <Nav className="justify-content-end">
                        <Contact></Contact>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}

export default Navbarmain;