export const MARKDOWN_TEXT = `
**Our Service** offers top-notch passport photo editing. The photos processed through our service adhere strictly to the required specifications, making them suitable for both online submission and printing.

The fee for a one-time photo edit is $5 USD dollars per photo.

Included in **Our Service** are:

- Comprehensive processing of the original photo, including photo replacement, size formatting, eye position adjustment, and quality enhancement.
- Technical support and a money-back guarantee.

You can preview the final result before payment, allowing you to try out different original photos before making a purchase.

### Refund Policy

We take pride in our high acceptance rate for processed photos. However, if your photo doesn't meet your needs, contact our customer support for the following options:

- **Free replacement of the original photo**: If your photo is rejected due to issues like incorrect angle or poor lighting, we will promptly replace it.
- **Correction of technical issues**: If the photo does not meet size and format requirements, we will correct these discrepancies.
- **Compliance validation**: Sometimes, technical errors occur during online submissions. If the photo meets all parameters, we will provide confirmation and suggest waiting for the website error to be fixed. If the issue persists, we will issue a refund.
- **Other reasons**: If officials do not accept the photo for any reason, we will initiate a refund.

---

### Terms of Service

By using our service, you agree to these terms, which may be modified without prior notice.

### Payments

Payments are processed by Square, a global leader in online payment solutions. Our site supports purchases using Visa, MasterCard, and other bank cards. Contact your card-issuing bank for more information on this authentication method.

### Payment Security

The information you provide (email, credit card number) is confidential and never shared. We do not see your credit card information; it is only available to our payment providers.

### Image Delivery

Images are delivered electronically via direct download from our site.

### Money-Back Guarantee

If a photo created by our service is not accepted by a government agency, we guarantee a refund.

---

### Privacy Policy

By using our Service, you are agreeing to these Terms. We may modify these Terms without any prior notice.

#### Definition

We prioritize protecting visitor and customer information. Personal information can identify a user (e.g., name, login, company name) and includes activity on the website (e.g., order details). Anonymous information cannot identify a specific user (e.g., website visit statistics).

#### Information Usage

We collect minimal information, such as your email address, order number, and transaction number, solely for order communication. We do not share personal information with other users or third parties, except as required by law.

#### Your Photographs

You retain all intellectual property rights to your photographs.

We do not distribute or sell your photo images. Your photographs are not used in advertising or any other marketing activities.

#### External Links

Our website may link to third-party websites, which we are not responsible for regarding information quality or confidentiality practices.

#### Liability Limitation

While we strive to follow our privacy policy, we cannot guarantee information security against factors beyond our control. Our website and content are provided "as-is" without guarantees. We are not liable for any losses due to restricted website access or usage of published information.

---

### Contact Information

If you have any questions about our policies, please contact us at the provided email address.

### Copyright

All text, images, HTML code, scripts, or data (Content) on this website are copyrighted by our service or suppliers. Redistribution of any Content is strictly prohibited.

### Disclaimer

We do not make specific promises about the service, such as its functionality, reliability, availability, or ability to meet your needs. The service is provided "as is." To the extent permitted by law, we exclude all warranties. You agree that we are not liable for any direct, indirect, incidental, special, consequential, or exemplary damages.

© 2024, by MyDocuPic
`;
