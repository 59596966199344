import Button from 'react-bootstrap/Button';
import { useDropzone } from 'react-dropzone';
import React, { useCallback } from 'react';

const DocumentUpload = (props) => {
    const onDrop = useCallback((acceptedFiles) => {
      console.log(acceptedFiles);
      props.onImageChange(acceptedFiles[0]);
      // You can handle the files here, e.g., upload to a server
    }, []);
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
    return (
      <div className="container mt-5">
        <div {...getRootProps()} className={`dropzone border p-4  text-center ${isDragActive ? 'bg-light' : ''}`} style={{ borderRadius: '10px' }}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <>Drag and Drop or  <Button variant="primary">Upload</Button>  a Photo</>
          }
        </div>
      </div>
    );
  }

  export default DocumentUpload;